const defaultQuestion = {
    id: 0,
    question: '',
    type: null,
    timer: null,
    active: false,
    sort: 0,
    max_attempts: 0,
    required: false,
    criterion_points: null,
    part: 0,
    created_at: '',
    updated_at: '',
    deleted_at: null,
    files: [],
    show_answer_note: false,
    correspondence_multi: false,
    answers: [],
    correspondences: [],
    correspondence_links: [],
    select_input_items: [],
    criterion_type: null,
    check_without_register: false,
    company_id: null,
    infinity_attempts: false,
    max_points: null,
    solution: null,
    test_id: null,
    question_criteria_by_sort: []
};
export const defaultQuestionFactory = () => ({
    id: 0,
    question: '',
    type: null,
    timer: null,
    active: false,
    sort: 0,
    max_attempts: 0,
    required: false,
    criterion_points: null,
    part: 0,
    created_at: '',
    updated_at: '',
    deleted_at: null,
    files: [],
    show_answer_note: false,
    correspondence_multi: false,
    check_without_register: false,
    answers: [],
    correspondences: [],
    correspondence_links: [],
    select_input_items: [],
    criterion_type: null,
    company_id: null,
    infinity_attempts: false,
    max_points: null,
    solution: null,
    test_id: null,
    question_criteria_by_sort: []
});
export default defaultQuestion;
