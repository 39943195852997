const defaultState = () => ({
    id: 0,
    name: '',
    surname: '',
    email: '',
    photo: '',
    photo_color: '',
    role: 'Администратор',
    errors: ''
});
export default defaultState;
