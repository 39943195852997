import { mixinActions, mixinMutations } from '@/store/mixins';
import buildQuestionNumber from './helpers/buildQuestionNumber';
import defaultState from './entities/defaultState';
import router from '@/router';
import { defaultQuestionFactory } from '@/store/modules/test/entities/defaultQuestion';
const state = defaultState();
const getters = {
    currentQuestionNumber(state) {
        if (!state.parts.length)
            return 0;
        const currentPart = state.parts.find((item) => item.questions.find(({ id }) => id === state.activeQuestionId));
        const { sort } = currentPart;
        const prevParts = state.parts.filter((item) => item.sort < sort);
        const prevQuestions = prevParts.reduce((acc, { questions }) => [...acc, ...questions], []);
        const index = currentPart.questions.findIndex(({ id }) => id === state.activeQuestionId);
        return prevQuestions.length + index + 1;
    },
    totalQuestionsCount(state) {
        if (!state.parts.length)
            return 0;
        const questions = state.parts.reduce((acc, { questions }) => [...acc, ...questions], []);
        return questions.length;
    },
    hasActiveQuestions(state) {
        const { parts } = state;
        if (!parts.length)
            return false;
        const copy = parts.map((part) => part.questions).flat();
        return copy.some((item) => item.active);
    },
    connectionsMaps(state) {
        const mainConnections = new Map();
        const dependentConnections = new Map();
        state.currentQuestion.correspondence_links?.forEach(({ main_id, dependent_id }) => {
            const mainIdConnections = mainConnections.get(main_id);
            mainIdConnections ? mainIdConnections.push(dependent_id) : mainConnections.set(main_id, [dependent_id]);
            const dependentIdConnections = dependentConnections.get(dependent_id);
            dependentIdConnections ? dependentIdConnections.push(main_id) : dependentConnections.set(dependent_id, [main_id]);
        });
        return { mainConnections, dependentConnections };
    }
};
const mutations = {
    ...mixinMutations(),
    addPart(state, payload) {
        state.parts.push(payload);
    },
    changeQuestion(state, payload) {
        const { name, value } = payload;
        const { currentQuestion } = state;
        if (!currentQuestion)
            return;
        currentQuestion[name] = value;
    },
    changeCorrespondence(state, payload) {
        const targetIndex = state.currentQuestion.correspondences.findIndex(({ id }) => id === payload.id);
        state.currentQuestion.correspondences = [
            ...state.currentQuestion.correspondences.slice(0, targetIndex),
            payload,
            ...state.currentQuestion.correspondences.slice(targetIndex + 1)
        ];
    },
    changeSelectItems(state, payload) {
        const targetIndex = state.currentQuestion.select_input_items.findIndex(({ id }) => id === payload.id);
        state.currentQuestion.select_input_items = [
            ...state.currentQuestion.select_input_items.slice(0, targetIndex),
            payload,
            ...state.currentQuestion.select_input_items.slice(targetIndex + 1)
        ];
    },
    changeTest(state, payload) {
        const isPayloadArray = Array.isArray(payload);
        const { test } = state;
        if (!test)
            return;
        if (isPayloadArray) {
            Object.values(payload).forEach((item) => {
                const { name, value } = item;
                test[name] = value;
            });
            return;
        }
        const { name, value } = payload;
        if (name === 'is_clone') {
            test[name] = value && typeof value === 'string' ? value : 0;
        }
        else {
            test[name] = value;
        }
    }
};
const actions = {
    ...mixinActions(),
    async getParts({ commit, state, dispatch }, activeQuestionId) {
        let data;
        try {
            const response = await this.getDataStore(`${state.testUrl}/questions`, {
                only: 'question,sort,part,active,type',
                groupByPart: '1'
            });
            data = response.data;
        }
        catch (e) {
            throw new Error(e);
        }
        // const { data } = response;
        const parts = Object.entries(data).reduce((acc, curr) => {
            const [key, value] = curr;
            const resValue = Array.isArray(value) ? value : Object.values(value);
            const questions = buildQuestionNumber(resValue);
            acc.push({
                sort: Number(key),
                questions: questions.sort((a, b) => a.sort - b.sort)
            });
            return acc;
        }, []).sort((a, b) => a.sort - b.sort);
        commit('setProperty', { name: 'parts', value: parts });
        if (activeQuestionId === 'create') {
            if (!parts || !parts.length) {
                commit('addPart', {
                    part: 1,
                    sort: 1,
                    questions: []
                });
                await dispatch('createQuestion', {
                    part: 1,
                    sort: 1
                });
                return;
            }
            const lastPart = parts[parts.length - 1];
            const sort = lastPart.questions ? lastPart.questions.length + 1 : 1;
            const part = lastPart.sort;
            const data = { sort, part };
            await dispatch('createQuestion', data);
            return;
        }
        const [firstPart] = parts;
        const { questions } = firstPart;
        const [firstQuestion] = questions;
        const { id } = firstQuestion;
        commit('setProperty', {
            name: 'activeQuestionId',
            value: isNaN(Number(activeQuestionId)) ? id : Number(activeQuestionId)
        });
    },
    async updateParts(_, questions) {
        const url = `${state.testUrl}/questions/multi_update`;
        const data = { questions };
        await this.postDataStore(url, data, 'POST');
    },
    async createTest({ rootGetters }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/tests`, {}, 'POST');
        return data.id;
    },
    async getInvites({ rootGetters, state, commit }, params) {
        const res = await this.getDataStore(`${rootGetters.API_URL}/invites`, {
            ...params,
            withCount: 'activatedUserInvites',
            testId: state.test.id
        });
        const { data } = res;
        commit('setProperty', { name: 'invite_count', value: data.length });
        return res;
    },
    async createInvite({ rootGetters }, payload) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/invites`, payload, 'POST');
        return data;
    },
    async updateInvite({ rootGetters }, { id, params }) {
        return await this.postDataStore(`${rootGetters.API_URL}/invites/${id}`, { ...params }, 'PUT');
    },
    async deleteInvite({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/invites/${id}`, {}, 'DELETE');
    },
    async getTest({ commit, state, rootGetters }, { test_id }) {
        commit('setProperty', {
            name: 'testUrl',
            value: `${rootGetters.API_URL}/tests/${test_id}`
        });
        const response = await this.getDataStore(state.testUrl, { with: 'teachers;subcategories_with_categories;lesson:type;coursePackages.course:id,name;questions:max_points,active' });
        const { data } = response;
        commit('setProperty', { name: 'test', value: data });
    },
    async changeTestActivity({ commit, dispatch, rootGetters, state }, { name, value }) {
        const action = value ? 'activate' : 'deactivate';
        const { test } = state;
        if (!test)
            return;
        const test_id = test ? test?.id : 0;
        const url = `${rootGetters.API_URL}/tests/${test_id}/${action}`;
        const response = await this.postDataStore(url, {}, 'POST');
        const { errors } = response;
        !errors ? dispatch('updateTest', { name, value }) : commit('setProperty', { name: 'errors', value: errors });
        return response;
    },
    async changeQuestionActivity({ commit, dispatch, rootGetters, state }, { name, value, question_id }) {
        const action = value ? 'activate' : 'deactivate';
        const url = `${rootGetters.API_URL}/questions/${question_id}/${action}`;
        const response = await this.postDataStore(url, {}, 'POST');
        const { errors } = response;
        if (!errors) {
            await dispatch('updateQuestion', { name, value, id: question_id });
            const hasActiveQuestion = state.parts
                .map((part) => part.questions)
                .flat()
                .some((question) => question.active);
            !hasActiveQuestion && dispatch('changeTestActivity', { name: 'active', value: hasActiveQuestion });
        }
        else {
            commit('setProperty', { name: 'errors', value: errors });
        }
        return response;
    },
    async getCurrentQuestion({ commit, state, rootGetters }, { activeQuestionId }) {
        const url = `${state.testUrl}/questions/${activeQuestionId}`;
        let data;
        try {
            const response = await this.getDataStore(url, { with: 'answers;correspondences;correspondenceLinks;selectInputItems' });
            data = response.data;
        }
        catch (e) {
            throw new Error(e);
        }
        if (!data)
            return;
        commit('setProperty', [
            { name: 'currentQuestion', value: data },
            {
                name: 'answersUrl',
                value: `${rootGetters.API_URL}/questions/${activeQuestionId}/answers`
            }
        ]);
    },
    async removeTestFile({ state, dispatch }, fileId) {
        const url = `${state.testUrl}/delete_media/${fileId}`;
        const response = await this.postDataStore(url, {}, 'DELETE');
        dispatch('showingSuccess', response);
    },
    async removeQuestionFile({ state, dispatch }, { fileId, question_id }) {
        const url = `${state.testUrl}/questions/${question_id}/delete_media/${fileId}`;
        const response = await this.postDataStore(url, {}, 'DELETE');
        dispatch('showingSuccess', response);
    },
    async createQuestion({ state, commit, dispatch }, data) {
        const currentPart = state.parts.find((item) => item.sort === data.part);
        if (!currentPart)
            return;
        const { testUrl } = state;
        const newQuestion = await this.postDataStore(`${testUrl}/questions`, data, 'POST');
        const { id } = newQuestion.data;
        commit('setProperty', { name: 'activeQuestionId', value: id });
        const questionData = { ...defaultQuestionFactory(), ...newQuestion.data };
        currentPart.questions.push(questionData);
        commit('setProperty', { name: 'currentQuestion', value: questionData });
        await router.push({ query: { question: id } });
        dispatch('showingSuccess', newQuestion);
    },
    async addPart({ state, commit, dispatch }, data) {
        const newQuestion = await this.postDataStore(`${state.testUrl}/questions`, data, 'POST');
        const { id } = newQuestion.data;
        commit('addPart', {
            sort: state.parts.length + 1,
            questions: [newQuestion.data]
        });
        commit('setProperty', { name: 'activeQuestionId', value: id });
        commit('setProperty', { name: 'currentQuestion', value: newQuestion.data });
        dispatch('showingSuccess', newQuestion);
    },
    async deleteAnswer({ state, commit, dispatch }, id) {
        const { answersUrl } = state;
        const url = `${answersUrl}/${id}`;
        const response = await this.postDataStore(url, {}, 'DELETE');
        dispatch('showingSuccess', response);
        commit('changeQuestion', {
            name: 'answers',
            value: [...state.currentQuestion.answers].filter((answer) => answer.id !== id)
        });
        return response;
    },
    async addAnswer({ commit, state, dispatch, rootGetters }, payload) {
        const { activeQuestionId } = state;
        const url = `${rootGetters.API_URL}/questions/${activeQuestionId}/answers`;
        const response = await this.postDataStore(url, payload, 'POST');
        const { data, errors } = response;
        if (errors)
            return;
        commit('changeQuestion', { name: 'answers', value: [...state.currentQuestion.answers, data] });
        dispatch('showingSuccess', response);
        return response;
    },
    async updateAnswer({ state, dispatch }, { id, data }) {
        const { answersUrl } = state;
        const { parent_answer_id, value, comment, correct, correct_position, sort } = data;
        const params = {
            parent_answer_id, value, comment, correct, correct_position, sort
        };
        const url = `${answersUrl}/${id}`;
        const response = await this.postDataStore(url, params, 'PUT');
        dispatch('showingSuccess', response);
        return response;
    },
    async updateAnswersSorting({ state }, data) {
        await this.postDataStore(`${state.answersUrl}/multi_update`, data, 'POST');
    },
    async updateQuestion({ state, commit, dispatch }, { name, value, id }) {
        const { testUrl, activeQuestionId } = state;
        const url = `${testUrl}/questions/${id || activeQuestionId}`;
        const response = await this.postDataStore(url, { [name]: value }, 'PUT');
        if (!id || id === state.activeQuestionId)
            commit('changeQuestion', { name, value });
        dispatch('showingSuccess', response);
    },
    async updateTest({ state, commit, dispatch }, payload) {
        const { testUrl } = state;
        let showAlert = true;
        const isPayloadArray = Array.isArray(payload);
        let noCommit = false;
        const data = {};
        if (isPayloadArray) {
            Object.values(payload).forEach(({ name, value }) => {
                data[name] = value;
            });
        }
        else {
            const { name, value, noDispatch } = payload;
            if (noDispatch) {
                showAlert = false;
            }
            if (payload.noCommit) {
                noCommit = true;
            }
            data[name] = value;
        }
        const response = await this.postDataStore(testUrl, data, 'PUT');
        if (!noCommit) {
            commit('changeTest', payload);
        }
        if (showAlert) {
            dispatch('showingSuccess', response);
        }
    },
    async updateJson({ state, dispatch }, data) {
        const { testUrl, activeQuestionId } = state;
        const url = `${testUrl}/questions/${activeQuestionId}`;
        const response = await this.postDataStore(url, { criterion_points: JSON.stringify(data) }, 'PUT');
        dispatch('showingSuccess', response);
    },
    async getTeachers({ commit, rootGetters }, params) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/tests/available_teachers`, {
            only: 'name,surname,email',
            ...params
        });
        commit('setProperty', { name: 'teachers', value: data });
        return data;
    },
    async getCourses({ commit, rootGetters }, params) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/courses`, {
            only: 'name',
            ...params
        });
        commit('setProperty', { name: 'courses', value: data });
        return data;
    },
    async getCategories({ rootGetters }, payload) {
        const { params } = payload;
        return await this.getDataStore(`${rootGetters.API_URL}/test_categories`, {
            with: 'subcategories',
            type: 'category',
            ...params
        });
    },
    async getSubcategories({ rootGetters }, payload) {
        const { params } = payload;
        return await this.getDataStore(`${rootGetters.API_URL}/test_categories`, {
            type: 'subcategory',
            ...params
        });
    },
    async list({ rootGetters }, { params }) {
        return await this.getDataStore(`${rootGetters.API_URL}/tests`, params);
    },
    async activate({ rootGetters }, { ids }) {
        await this.postDataStore(`${rootGetters.API_URL}/tests/activate`, { tests_id: ids });
    },
    async deactivate({ rootGetters }, { ids }) {
        await this.postDataStore(`${rootGetters.API_URL}/tests/deactivate`, { tests_id: ids });
    },
    async delete({ rootGetters }, { ids }) {
        await this.postDataStore(`${rootGetters.API_URL}/test/delete`, { tests_id: ids }, 'DELETE');
    },
    async getSuccessfulMax({ state, commit }) {
        const { testUrl } = state;
        const { data } = await this.getDataStore(`${testUrl}`, { successful_points_max: true });
        commit('setProperty', { name: 'successful_points_max', value: Number(data.successful_points_max) });
        commit('setProperty', { name: 'max_free_points', value: Number(data.max_free_points) });
        return data;
    },
    async replicateTest({ rootGetters }, test_id) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/tests/${test_id}/replicate`, {}, 'POST');
        return data;
    },
    async deleteQuestion({ state }, id) {
        const { data } = await this.postDataStore(`${state.testUrl}/questions/${id}`, {}, 'DELETE');
        return data;
    },
    async cloneQuestion({ state }) {
        const { data } = await this.postDataStore(`${state.testUrl}/questions/${state.currentQuestion.id}/replicate`);
        return data;
    },
    // Вопрос соответствие
    async createCorrespondenceAnswer({ state, commit, rootGetters }, { value, type, sort }) {
        const { activeQuestionId } = state;
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/questions/${activeQuestionId}/correspondences`, {
            value,
            type
        }, 'POST');
        if (errors)
            return { errors };
        commit('changeQuestion', {
            name: 'correspondences',
            value: [...state.currentQuestion.correspondences, { ...data, sort }]
        });
        return { data };
    },
    async updateCorrespondenceAnswer({ state, commit, rootGetters }, { value, type, id }) {
        const { activeQuestionId } = state;
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/questions/${activeQuestionId}/correspondences/${id}`, {
            value,
            type
        }, 'PUT');
        if (errors)
            return { errors };
        commit('changeCorrespondence', data);
        return { data };
    },
    async deleteCorrespondenceAnswer({ state, commit, rootGetters }, { element_id, type }) {
        const { activeQuestionId } = state;
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/questions/${activeQuestionId}/correspondences/${element_id}`, {}, 'DELETE');
        if (errors)
            return { errors };
        commit('changeQuestion', {
            name: 'correspondences',
            value: state.currentQuestion.correspondences.filter((answer) => answer.id !== element_id)
        });
        commit('changeQuestion', {
            name: 'correspondence_links',
            value: state.currentQuestion.correspondence_links.filter((link) => (type === 'dependent' ? link.dependent_id !== element_id : link.main_id !== element_id))
        });
        return { data };
    },
    async linkCorrespondenceAnswer({ state, commit, rootGetters }, { main_id, dependent_id, action }) {
        const { activeQuestionId } = state;
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/questions/${activeQuestionId}/correspondences/${main_id}/${action}/${dependent_id}`, {}, 'POST');
        if (errors)
            return { errors };
        commit('changeQuestion', {
            name: 'correspondence_links',
            value: action === 'attach' ? [...state.currentQuestion.correspondence_links, {
                    main_id,
                    dependent_id
                }] : state.currentQuestion.correspondence_links.filter((link) => !(link.main_id === main_id && link.dependent_id === dependent_id))
        });
        return { data };
    },
    // Вопрос с выбором в тексте
    async createAnswerSelect({ state, commit, rootGetters }, { answer_id, value, correct, sort }) {
        const { activeQuestionId } = state;
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/questions/${activeQuestionId}/answers/${answer_id}/select_input`, {
            value,
            correct
        }, 'POST');
        if (errors)
            return { errors };
        commit('changeQuestion', {
            name: 'select_input_items',
            value: [...state.currentQuestion.select_input_items, { ...data, sort }]
        });
        return { data };
    },
    async updateAnswerSelect({ state, commit, rootGetters }, { answer_id, input_id, value, correct }) {
        const { activeQuestionId } = state;
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/questions/${activeQuestionId}/answers/${answer_id}/select_input/${input_id}`, { value, correct }, 'PUT');
        if (errors)
            return { errors };
        commit('changeSelectItems', data);
        return { data };
    },
    async deleteAnswerSelect({ state, commit, rootGetters }, { answer_id, input_id }) {
        const { activeQuestionId } = state;
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/questions/${activeQuestionId}/answers/${answer_id}/select_input/${input_id}`, {}, 'DELETE');
        if (errors)
            return { errors };
        commit('changeQuestion', {
            name: 'select_input_items',
            value: state.currentQuestion.select_input_items.filter(({ id }) => id !== input_id)
        });
        return { data };
    },
    async getUserList({ rootGetters }, payload) {
        return await this.getDataStore(`${rootGetters.API_URL}/users`, {
            search: payload,
            only: 'name,surname,email'
        });
    },
    async getAllowedUsers({ rootGetters }, payload) {
        return await this.getDataStore(`${rootGetters.API_URL}/tests/${payload}/allow_test_users`, { with: 'user' });
    },
    async addUsersToAllowed({ rootGetters }, payload) {
        return await this.postDataStore(`${rootGetters.API_URL}/tests/${payload.test}/attach_allow_users`, { allow_users_ids: payload.users });
    },
    async deleteUsersFromAllowed({ rootGetters }, payload) {
        return await this.postDataStore(`${rootGetters.API_URL}/tests/${payload.test}/detach_allow_users`, { allow_users_ids: payload.users });
    },
    async getTemplatesList({ rootGetters }, { params }) {
        return await this.getDataStore(`${rootGetters.API_URL}/question_criterion_templates`, params);
    },
    async createTemplateItem({ rootGetters }, payload) {
        const { id, ...rest } = payload;
        const params = {
            ...rest,
            with: 'questionCriteriaBySort',
            withCount: 'questionCriteria'
        };
        try {
            const { data } = await this.postDataStore(`${rootGetters.API_URL}/question_criterion_templates`, params, 'POST');
            return data;
        }
        catch (e) {
            return null;
        }
    },
    async getTemplateItem({ rootGetters }, id) {
        try {
            const { data } = await this.getDataStore(`${rootGetters.API_URL}/question_criterion_templates/${id}`, {
                with: 'questionCriteriaBySort',
                withCount: 'questionCriteria',
                sort: 'sort'
            });
            return data;
        }
        catch (e) {
            return null;
        }
    },
    async updateTemplateItem({ rootGetters }, payload) {
        const { id, ...rest } = payload;
        const params = {
            ...rest,
            with: 'questionCriteriaBySort',
            withCount: 'questionCriteria'
        };
        try {
            const { data } = await this.postDataStore(`${rootGetters.API_URL}/question_criterion_templates/${id}`, params, 'PUT');
            return data;
        }
        catch (e) {
            return null;
        }
    },
    async deleteTemplateItem({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/question_criterion_templates/${id}`, {}, 'DELETE');
    },
    async getQuestionCriterias({ rootGetters }, questionId) {
        try {
            const { data } = await this.getDataStore(`${rootGetters.API_URL}/question_criteria`, {
                questionId,
                desc: 1,
                sort: 'sort'
            });
            return data;
        }
        catch (e) {
            return [];
        }
    },
    async createCriteria({ rootGetters }, payload) {
        const isMultiple = Array.isArray(payload);
        if (!isMultiple) {
            return this.postDataStore(`${rootGetters.API_URL}/question_criteria`, payload, 'POST');
        }
        const result = await Promise.all(payload.map((item) => {
            return this.postDataStore(`${rootGetters.API_URL}/question_criteria`, item, 'POST');
        }));
        const errors = result.map(({ errors }) => errors).filter(e => e);
        if (errors)
            return { errors };
        return result;
    },
    async getCriteria({ rootGetters }, id) {
        try {
            const { data } = await this.getDataStore(`${rootGetters.API_URL}/question_criteria/${id}`, {});
            return data;
        }
        catch (e) {
            return null;
        }
    },
    async updateCriteria({ rootGetters }, payload) {
        const isMultiple = Array.isArray(payload);
        if (!isMultiple) {
            const { id, ...rest } = payload;
            return this.postDataStore(`${rootGetters.API_URL}/question_criteria/${id}`, rest, 'PUT');
        }
        const result = await Promise.all(payload.map(({ id, ...rest }) => {
            return this.postDataStore(`${rootGetters.API_URL}/question_criteria/${id}`, rest, 'PUT');
        }));
        const errors = result.map(({ errors }) => errors).filter(e => e);
        if (errors)
            return { errors };
        return result;
    },
    async deleteCriteria({ rootGetters }, payload) {
        const isMultiple = Array.isArray(payload);
        if (!isMultiple) {
            return this.postDataStore(`${rootGetters.API_URL}/question_criteria/${payload.id}`, {}, 'DELETE');
        }
        const result = await Promise.all(payload.map(({ id }) => {
            return this.postDataStore(`${rootGetters.API_URL}/question_criteria/${id}`, {}, 'DELETE');
        }));
        const errors = result.map(({ errors }) => errors).filter(e => e);
        if (errors)
            return { errors };
        return result;
    },
    async addTemplateCriteriasToQuestion({ rootGetters }, { questionId, templateId }) {
        return await this.postDataStore(`${rootGetters.API_URL}/question_criterion_templates/${templateId}/questions/${questionId}/add_criteria`, {}, 'POST');
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
